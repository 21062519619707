import { get, post } from '@/serve/axios'

// ICM设备管理
// 1. Icm设备列表
export const getICMDeviceList = (params) => get('admin/v1/device/list', { params })

// 2. 是否允许编辑
export const isEditICMDeviceInfo = (id) => get(`admin/v1/icm/device/bind-status?id=${id}`)

// 3. 编辑icm设备
export const editICMDeviceInfo = (params) => post('admin/v1/device/edit', params)

// 4. 添加设备
export const addICMDevice = (params) => post('admin/v1/device/add', params)

// 5. Icm设备传输记录查询
export const getIcmSampleRecordLogs = (params) => get('admin/v1/icm/data/sample-record/logs', { params })

// 事件及备注信息查询
export const getIcmEpisodeRemarkSampleRecord = (params) => get('admin/v1/icm/data/episode-sample-record/remark', { params })
