<template>
  <el-popover
    placement="left-start"
    width="300"
    trigger="hover"
    v-if="remarkList && remarkList.length > 0">
    <!-- 弹窗内容 -->
    <div class="remark-popover">
      <div
        v-for="(item, index) in remarkList"
        :key="index"
        class="remark-item">
        <span>{{ index + 1 }}、{{ item }}</span>
      </div>
    </div>

    <!-- 触发内容 -->
    <div slot="reference">
      {{ remarkList[0] }}
    </div>
  </el-popover>
</template>

<script>
export default {
  props: {
    remarkList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.remark-popover {
  max-height: 400px;
  overflow-y: auto;
}
.remark-item {
  border-bottom: 1px solid #e5e5e5;
  padding: 6px 0;
  &:last-child {
    border-bottom: none;
  }
}
</style>
