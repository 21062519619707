<template>
  <div class="icm-events" style="margin: 0 24px;">
    <part-title title="ICM事件"></part-title>
    <div class="search">
      <template>
        <span class="el-span">患者姓名：</span>
        <el-input
          size="small"
          class="el-input"
          v-model="searchData.patientName"
          placeholder="请输入患者姓名"
          @input="updatePageNo"
        >
        </el-input>

        <!-- 事件类型下拉 -->
        <span class="el-span">事件类型：</span>
        <el-select
          class="el-select"
          size="small"
          v-model="searchData.eventType"
          placeholder="请选择"
          clearable
          @change="updatePageNo">
          <el-option
            v-for="item in optionsEventType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>

        <!-- 备注内容下拉 -->
        <span class="el-span">备注内容：</span>
        <el-select
          class="el-select"
          size="small"
          v-model="searchData.remarkType"
          placeholder="请选择"
          clearable
          @change="updatePageNo">
          <el-option
            v-for="item in optionsRemarkType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>

        <!-- 上传起始时间下拉 -->
        <span class="el-span">上传时间：</span>
        <el-select
          class="el-select"
          size="small"
          v-model="searchData.startUploadingDays"
          placeholder="请选择"
          clearable
          @change="updatePageNo">
          <el-option
            v-for="item in optionsTimeRange"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>

        <el-button
          type="info"
          size="small"
          @click="reset"
          class="reset-btn">
          重置
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSearch"
          class="search-btn">
          查询
        </el-button>
      </template>
    </div>
    <el-table
      v-loading="isLoading"
      element-loading-text="数据加载中..."
      element-loading-background="rgba(255, 255, 255, 0.7)"
      :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="hospitalName"
        label="植入医院">
      </el-table-column>
      <el-table-column
        align="center"
        prop="patientName"
        label="患者姓名">
      </el-table-column>
      <el-table-column
        align="center"
        prop="episodeId"
        label="事件序号（医生端）">
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceEpisodeId"
        label="事件序号（ICM）">
      </el-table-column>
      <el-table-column
        align="center"
        prop="eventType"
        label="事件类型"
        :formatter="mapEventType">
      </el-table-column>
      <el-table-column
        align="center"
        prop="eventTime"
        :formatter="formatEventTime"
        label="事件时间">
      </el-table-column>
      <el-table-column
        align="center"
        prop="uploadMethod"
        label="上传通道"
        :formatter="mapUploadMethod">
      </el-table-column>
      <el-table-column
        align="center"
        prop="startTime"
        :formatter="formatStartTime"
        label="上传起始时间">
      </el-table-column>
      <el-table-column
        prop="remarkInfo"
        label="心电图备注"
        align="center"
        width="240">
        <template slot-scope="scope">
          <remark-popover :remark-list="scope.row.remarkInfo" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="operatorName"
        label="备注人">
        <template slot-scope="scope">
          <remark-popover :remark-list="scope.row.operatorName" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="remarkTime"
        label="备注时间">
        <template slot-scope="scope">
          <remark-popover :remark-list="scope.row.remarkTime" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        width="120">
        <template slot-scope="scope">
          <a
            href="javascript:"
            @click="openDoctorPage(scope.row.episodeId)"
            style="color: #409EFF; cursor: pointer;">
            前往医生端
          </a>
        </template>
      </el-table-column>
    </el-table>
    <Page
      ref="pageRef"
      @handleSizeChange="sizeChange"
      @handleCurrentChange="pageChange"
      :page="currentPage"
      :page-size="searchData.pageSize"
      :total="totalPage"
    ></Page>
    <div style="height: 80px"></div>
  </div>
</template>

<script>
import { getIcmEpisodeRemarkSampleRecord } from '@/serve/module/ICMDeviceManage'
import PartTitle from '@/components/Part'
import Page from '@/components/Page'
import RemarkPopover from '@/components/RemarkPopover'
import { EVENT_TYPE_ALL, EVENT_TYPE_MANUAL, EVENT_TYPE_AF, EVENT_TYPE_AT_AF, EVENT_TYPE_BRADY, EVENT_TYPE_PAUSE, EVENT_TYPE_VT_VF } from '@/utils/constants'

const moment = require('moment')
export default {
  components: {
    PartTitle,
    Page,
    RemarkPopover
  },
  data () {
    return {
      isLoading: false, // loading状态
      moment: moment,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20, // 每页条数
        patientName: null,
        eventType: 99, // 事件类型
        remarkType: 'manual_remark', // 备注类型
        startUploadingDays: 1 // 时间范围
      },
      totalPage: 1,
      tableData: [],
      isVisibleDetailModel: false,

      modelId: null,
      statusText: '-',
      currentSn: '',
      currentPage: 1,

      // 选项配置
      optionsEventType: [
        { value: EVENT_TYPE_ALL, label: '全部' },
        { value: EVENT_TYPE_MANUAL, label: '患者手动触发' },
        { value: EVENT_TYPE_BRADY, label: 'Brady' },
        { value: EVENT_TYPE_PAUSE, label: 'Pause' },
        { value: EVENT_TYPE_AF, label: 'AF' },
        { value: EVENT_TYPE_VT_VF, label: 'VT/VF' },
        { value: EVENT_TYPE_AT_AF, label: 'AT/AF' }
      ],
      optionsRemarkType: [
        { value: 'all', label: '全部' },
        { value: 'manual_remark', label: '有人工备注' },
        { value: 'without_remark', label: '无备注' }
      ],
      optionsTimeRange: [
        { value: 1, label: '近1天' },
        { value: 3, label: '近3天' },
        { value: 7, label: '近7天' },
        { value: 31, label: '近一个月' },
        { value: 180, label: '近半年' },
        { value: 365, label: '近一年' }
      ]
    }
  },
  watch: {
    'searchData.eventType': 'updatePageNo',
    'searchData.remarkType': 'updatePageNo',
    'searchData.startUploadingDays': 'updatePageNo'
  },
  mounted () {
    this.getList()
  },
  methods: {
    onSearch () {
      this.getList()
    },
    reset () {
      // 重置搜索参数
      this.searchData = {
        ...this.searchData, // 保留分页参数
        patientName: null,
        eventType: 99, // 事件类型
        remarkType: 'manual_remark', // 备注类型
        startUploadingDays: 1 // 时间范围
      }
      // 重置分页到第一页
      this.searchData.pageNo = 1
      // 重新获取列表
      this.getList()
    },
    async getList () {
      this.isLoading = true // 开始加载
      try {
        const res = await getIcmEpisodeRemarkSampleRecord(this.searchData)
        console.log('获取列表:', res)
        this.tableData = res.data.data || []
        this.totalPage = res.data.total
        this.pageNo = res.data.pageNo
        this.pageSize = res.data.pageSize
        this.currentPage = this.searchData.pageNo
        console.log('currentPage is : ', this.currentPage)
        // 设置当前显示页面
        // const myPage = this.$refs.pageRef
        // myPage.updatePage(res.data.pageNo)
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'error'
        })
      } finally {
        this.isLoading = false // 无论成功失败都关闭loading
      }
    },
    sizeChange (val) {
      this.searchData.pageSize = val
      this.getList()
    },
    pageChange (val) {
      this.searchData.pageNo = val
      this.getList()
    },
    formatStartTime (row) {
      return moment.unix(row.startTime / 1000).format('Y-MM-DD HH:mm:ss')
    },
    formatEventTime (row) {
      return moment.unix(row.eventTime / 1000).format('Y-MM-DD HH:mm:ss')
    },
    mapUploadMethod (row) {
      const methodMap = {
        0: '小程序',
        1: '家庭监护'
      }
      return methodMap[row.uploadMethod] || '未知通道'
    },
    mapEventType (row) {
      const methodMap = {
        2: '患者手动触发',
        7: 'AF',
        8: 'AT/AF',
        9: 'Brady',
        10: 'Pause',
        11: 'VT/VF'
      }
      return methodMap[row.eventType] || '未知类型事件'
    },
    updatePageNo (newVal, oldVal) {
      if (newVal !== oldVal) {
        // 重置分页至第一页
        this.searchData.pageNo = 1
      }
    },
    openDoctorPage (episodeId) {
      const baseUrl = process.env.VUE_APP_DOCTOR_WEB_URL
      const externalUrl = `${baseUrl}/patientEvenDetail?eventId=${episodeId}`
      window.open(externalUrl, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.icm-events {
  .search {
    margin-bottom: 12px;
    height: 32px;
    line-height: 32px;
    .el-input {
      width: 130px;
      margin-right: 24px;
    }
    .el-select {
      width: 125px !important;
      margin-right: 12px;
    }
    .el-span {
      font-weight: 400;
      font-size: 14px;
    }
    .reset-btn {
      margin-left: 12px; // 与下拉框保持间距
    }
    // .search-btn {
    //   font-size: 14px;
    // }
  }
  .add-new {
    margin-bottom: 12px;
  }
}
</style>
